'use client'

import { Button, Heading } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { getMarketingChannel, useSplitStore } from '@chaseweb/utils/split'
import { fetchProspectId } from '@chaseweb/utils/tracking'
import { useSearchParams } from 'next/navigation'
import type { FormEvent, MouseEvent } from 'react'
import { useEffect, useState } from 'react'

import { useChaseEnv } from '@/lib/hooks'
import { useConsentStore } from '@/lib/stores'

type TreatmentValueObjecType = { treatment: 'on' | 'off'; config: string }

type TreatmentValueType = 'on' | 'off' | TreatmentValueObjecType

const stopPropagation = (event: MouseEvent) => event.stopPropagation()

const useSplitControl = () => {
  const env = useChaseEnv()
  const split = useSplitStore()
  const marketingChannel = getMarketingChannel()
  const consents = useConsentStore((store) => store.consents)
  const consentsCaptured = useConsentStore((store) => store.captured)

  return async () => {
    if (!env) return
    const webProspectId = fetchProspectId()

    const { splitApiKey, splitProxyUrl } = env
    split.reset()
    await split.init({
      apiKey: splitApiKey,
      proxyUrl: splitProxyUrl ?? null,
      webProspectId,
      clientAttributes: {
        marketingChannel,
      },
      consentsCaptured: consentsCaptured,
      hasConsentToSendImpressions: consents.Analytical,
    })
  }
}

const invertTreatment = (currTreatment: 'on' | 'off') => {
  return currTreatment === 'on' ? 'off' : 'on'
}

const getTreatment = (splitFlagValue: TreatmentValueType) => {
  if (isObjectTreatment(splitFlagValue)) {
    return splitFlagValue.treatment
  }
  return splitFlagValue
}

const isObjectTreatment = (
  splitFlagValue: TreatmentValueType,
): splitFlagValue is { treatment: 'on' | 'off'; config: string } => {
  return typeof splitFlagValue === 'object'
}

export const SplitMenu = ({ splitKeyList }: { splitKeyList: string[] }) => {
  const searchParams = useSearchParams()
  const reInitSplit = useSplitControl()
  const [splitioSessionData, setSplitioSessionData] = useState<
    Record<string, TreatmentValueType>
  >({})
  const isDevSplitActive = searchParams.get('split') !== null
  useEffect(() => {
    if (isDevSplitActive) {
      const sessionSplitio = JSON.parse(
        sessionStorage.getItem('splitio') ?? '{}',
      )
      setSplitioSessionData(sessionSplitio)
    }
  }, [isDevSplitActive])

  const handleOnClick = (key: string) => async () => {
    const newSplitioSessionData = { ...splitioSessionData }
    if (isObjectTreatment(newSplitioSessionData[key])) {
      ;(newSplitioSessionData[key] as TreatmentValueObjecType).treatment =
        invertTreatment(
          (newSplitioSessionData[key] as TreatmentValueObjecType).treatment,
        )
    } else if (typeof newSplitioSessionData[key] === 'string') {
      newSplitioSessionData[key] = invertTreatment(
        newSplitioSessionData[key] as 'on' | 'off',
      )
    }
    sessionStorage.setItem('splitio', JSON.stringify(newSplitioSessionData))
    setSplitioSessionData(newSplitioSessionData)
    await reInitSplit()
  }

  const handleSplitReset = async () => {
    if (splitKeyList.length) {
      if (isDevSplitActive) {
        window.location.search = ''
      } else {
        window.location.search = 'split='
      }
      await reInitSplit()
    }
  }

  const handleSubmitConfigChange =
    (key: string) => (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const formData = new FormData(event.currentTarget)
      const newConfig: Record<string, string> = {}
      for (const [configKey, configValue] of formData.entries()) {
        newConfig[configKey] = configValue as string
      }
      const newSplitioSessionData = { ...splitioSessionData }
      if (typeof newSplitioSessionData[key] === 'object') {
        ;(newSplitioSessionData[key] as TreatmentValueObjecType).config =
          JSON.stringify(newConfig)
      }
      sessionStorage.setItem('splitio', JSON.stringify(newSplitioSessionData))
      setSplitioSessionData(newSplitioSessionData)
      reInitSplit()
    }

  return (
    <div
      className={cn('tw-relative tw-rounded-tr-xl', {
        '[&>div:last-child]:hover:tw-left-full [&>div:last-child]:hover:tw-opacity-100':
          isDevSplitActive,
      })}
    >
      <div
        onClick={handleSplitReset}
        className={cn('tw-h-full tw-w-full tw-cursor-pointer tw-p-4', {
          'tw-text-grey50': !splitKeyList.length,
        })}
        data-testid="splitMenuButton"
      >
        {isDevSplitActive ? <>✅</> : <>☑️</>} Split config{' '}
        <span className="tw-text-blue30">➡</span>
      </div>
      {splitKeyList.length > 0 && isDevSplitActive && (
        <div className="tw-absolute -tw-left-[1000vw] tw-bottom-0 tw-p-2 tw-opacity-0 tw-transition-opacity">
          <div
            className={cn(
              'tw-rounded-xl tw-border tw-border-blue50 tw-bg-white',
            )}
          >
            <table>
              <tbody
                className={cn(
                  '[&>tr>td]:tw-cursor-pointer [&>tr>td]:tw-p-2 last:[&>tr>td]:tw-pl-0 last:[&>tr]:tw-rounded-b-xl',
                  '[&>tr:first-child>td:first-child]:tw-rounded-tl-xl [&>tr:first-child>td:last-child]:tw-rounded-tr-xl [&>tr:hover>td]:tw-bg-grey80 [&>tr:last-child>td:first-child]:tw-rounded-bl-xl [&>tr:last-child>td:last-child]:tw-rounded-br-xl',
                )}
              >
                {splitKeyList.map((key) => {
                  const isTreatmentOn =
                    getTreatment(splitioSessionData[key]) === 'on'
                  let configKeys: string[] = []
                  let config: Record<string, string> = {}
                  if (typeof splitioSessionData[key] === 'object') {
                    config =
                      JSON.parse(
                        (splitioSessionData[key] as TreatmentValueObjecType)
                          .config,
                      ) ?? {}
                    configKeys = Object.keys(config)
                  }
                  return (
                    <tr
                      key={key}
                      onClick={handleOnClick(key)}
                      className={cn(
                        'tw-relative [&>td:last-child>div]:hover:tw-left-full [&>td:last-child>div]:hover:tw-opacity-100',
                        {
                          '[&>td:last-child>div]:hover:-tw-left-[1000vw]':
                            !isTreatmentOn,
                        },
                      )}
                    >
                      <td>{isTreatmentOn ? <>✅</> : <>☑️</>}</td>
                      <td>{key}</td>
                      <td>
                        <span
                          className={cn('tw-text-grey60', {
                            'tw-text-grey40': isTreatmentOn,
                            'tw-text-transparent': !configKeys.length,
                          })}
                        >
                          ➡
                        </span>
                      </td>
                      <td className="split-submenu" onClick={stopPropagation}>
                        {configKeys.length > 0 && (
                          <div className="tw-absolute -tw-bottom-2 -tw-left-[1000vw] tw-p-2 tw-opacity-0 tw-transition-opacity">
                            <div className="tw-rounded-xl tw-border tw-border-blue50 tw-bg-white tw-p-2 tw-text-left">
                              <form
                                onSubmit={handleSubmitConfigChange(key)}
                                autoComplete="off"
                              >
                                <Heading type="h3">Config:</Heading>
                                <hr className="tw-my-2 tw-border-grey60" />
                                {configKeys.map((configKey) => (
                                  <div key={configKey} className="tw-mb-2 ">
                                    <label className="tw-font-bold">
                                      {configKey}:
                                    </label>
                                    <input
                                      name={configKey}
                                      defaultValue={config[configKey]}
                                      className="tw-w-96 tw-rounded-md tw-border tw-border-grey60 tw-p-1"
                                    />
                                  </div>
                                ))}
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  type="submit"
                                >
                                  Apply
                                </Button>
                              </form>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
